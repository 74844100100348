<template>
	<div>
		<rxNavBar title="请假" androidOrIOSFlag="true"></rxNavBar>
		<div class="header" @click="iwleave">
			<div class="applicationClenaing">
				<span>我要请假</span>
			</div>
			<div class="imgDiv">
				<img class="clenaing_img" src="../../../assets/images/iwleaveImg.png" />
			</div>
		</div>
		<!-- 主体列表 -->
		<div class="bo-dy">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" :finished-text="isEmpty ? '' : '已经到底了'" @load="queryStaffList()">
			<div class="box" v-for="(item,i) in leaveList " :key="i">
				<!-- 左上角的状态 -->
				<div  :class="item.vacationStatusId=='0'?'top-title-orange':item.vacationStatusId=='1'?'top-title-green':item.vacationStatusId=='4' ? 'top-title-violet' : item.vacationStatusId=='2' ? 'top-title-red' :'top-title-blue'">
					{{ item.labelText }}
				</div>
				<div class="leaveDetail">
					<span class="spanOne">{{ item.vacationType ? item.vacationType : '暂无'}}</span>
				</div>
				<div class="leaveDetail">
					<span class="spanOne">{{ item.vacationBeginTime ? item.vacationBeginTime : '暂无'}}
						至 {{item.vacationEndTime ? item.vacationEndTime : '暂无'}}</span>
				</div>
				<div class="leaveDetail">
					<span class="spanTwo">时长: </span>
					<span class="spanThree">{{item.vacationDay ? item.vacationDay : '暂无'}}</span>
				</div>
				<div class="leaveDetail">
					<span  class="spanTwo">工作交接人: </span>
					<span class="spanThree">{{item.handoverStaff ? item.handoverStaff : '暂无'}}</span>
				</div>
				<div class="leaveDetailRemarks">
					<span  class="spanTwo">事由: </span>
					<span class="spanThree">{{item.remarks ? item.remarks : '暂无'}}</span>
				</div>
				<div class="rule" v-if="item.vacationStatusId==0||item.vacationStatusId==2||item.vacationStatusId==4"></div>
				<!-- 最下方 -->
				<div class="cancelBtnDiv" v-if="item.vacationStatusId==0||item.vacationStatusId==2||item.vacationStatusId==4">
					<button class="cancelBtn" @click="editLeave(item)" style="margin-right: 10px" v-if="item.vacationStatusId == 2">修改</button>
					<button class="cancelBtn" @click="cancelLeaveFun(item)">取消</button>
				</div>
			</div>
				</van-list>
				<!--空状态显示-->
				<common-empty v-if="isEmpty"></common-empty>
			</van-pull-refresh>
<!--			取消请假的弹窗-->
			<van-popup position="bottom" v-model="cancelLeaveShow" :style="{ height: 'auto' }" >
				<img class="cancelOrdersImg"  src="../../../assets/images/colon.png">
				<div class="cnacelOrdersText" >确认</div>
				<div class="confirmCancelOrdersText" >取消该请假？</div>
				<button class="cancelLeaveBtn" @click= "cancelLeave()"> 确定</button>
			</van-popup>
		</div>
	</div>

</template>

<script>
	import { Picker, List, PullRefresh, Popup} from "vant";
	import RxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import {cancelLeave, queryLeaveList, queryStaffList, vacationListInit} from "../../../getData/getData";
	import {
		getStaffId,
		responseUtil
	} from "../../../libs/rongxunUtil";

	export default {
		name: "myOrder",
		components: {
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			[Picker.name]: Picker,
			[List.name]: List,
			[Popup.name]: Popup,
			[PullRefresh .name]: PullRefresh,
			RxNavBar

		},
		data() {
			return {
				//请假ID
				id : '',
				cancelLeaveShow : false,
				//下拉刷新
				isLoading : false,
				//初始化数据列表
				leaveList : [],
				//分页
				loading: false,
				finished: false,
				//空状态
				isEmpty : false,
				//页码
				currentPage :1,
			}
		},

		created() {

		},
		//初始化
		mounted() {},
		methods: {
			//取消请假
			cancelLeaveFun(item){
				this.cancelLeaveShow = true
				this.id = item.id
			},
			//确认取消调用接口方法
			cancelLeave (){
				let that = this
				that.cancelLeaveShow = false
				let initData = {}
				initData.id = that.id
				initData.staff_id = getStaffId()
				initData.vacationStatus = 5
				cancelLeave(initData).then(function (response) {
					responseUtil.dealResponse(that,response,()=>{
						responseUtil.alertMsg(that,'操作成功')
							that.onload()
					})
				})
			},
			onload(){
				this.leaveList = []
				this.finished = false
				this.loading = false
				this.currentPage = 1
				this.vacationListInit()
			},
			onRefresh() {
				setTimeout(() => {
					this.onload()
					responseUtil.alertMsg(this,'刷新成功')
					this.isLoading = false;
				}, 1000);
			},
			//列表初始化
			vacationListInit(){
				let that = this
				let initData = {}
				initData.staff_id = getStaffId()
				initData.currentPage = that.currentPage
				initData.numberPage = 3
				initData.remarks = that.staffName
				initData.approvalStatus = ''
				queryLeaveList(initData).then(function (response) {
					responseUtil.dealResponse(that,response,()=>{
						let page = response.data.data.pageCount
						if(that.currentPage==1&&page == 0){
							that.isEmpty = true
						}
						if(page>0){
							for(let i=0;i<response.data.data.data.length;i++){
								// response.data.data.data[i].vacationTimeLong = Math.ceil((response.data.data.data[i].vacationTimeLong)/24)
								that.leaveList.push(response.data.data.data[i])
							}
						}
						// 加载状态结束
						that.loading = false;
						that.currentPage++
						// 数据全部加载完成
						if (that.leaveList.length >= page) {
							that.finished = true;
						}
					})
				})

			},
			//查询员工列表
			queryStaffList(){
				let that = this
				let initData = {}
				initData.id = getStaffId()
				initData.searchName = that.referrerText
				queryStaffList(initData).then(function (response) {
					responseUtil.dealResponse(that,response,()=>{
						that.staffName = response.data.data.data[0] != undefined ? response.data.data.data[0].staffName : ''
						that.vacationListInit()
					})
				})
				return that.staffName
			},
			//我要请假
			iwleave(){
				this.$router.push({
					name:'iwleave',
					query:{
					}
				})
			},
			//修改
			editLeave(item){
				this.$router.push({
					name:'iwleave',
					query:{
						id:item.id,
					}
				})
			}
		},

	}
</script>

<style scoped lang="less">
	.header {
		margin: 15px auto;
		background-color: #DCF5FF;
		display: flex;
		align-items: center;
		height: 66px;
		width: 92%;
		border-radius: 8px;
		overflow: hidden;
	}
	.imgDiv{
		width: 25%;
		height: 100%;
	}
	.clenaing_img {
		width: 100%;
		height: 100%;
		border-radius: 8px;
		background-color: #DCF5FF;
	}
	.applicationClenaing {
		font-weight: bold;
		width: 75%;
		height: 21px;
		color: rgba(34, 34, 34, 1);
		font-size: 14px;
		font-family: PingFangSC-Semibold;
		span{
			margin-left: 30px;
		}
	}
	.bo-dy {
		margin-top: 25px;
	}
	.box {
		width: 92%;
		background-color: white;
		border-radius: 8px;
		margin: 0 auto 17px;
		padding-bottom: 1px;
	}
	/*状态*/
	.top-title-orange,.top-title-green,.top-title-red,.top-title-blue,.top-title-blue,.top-title-violet {
		width: 38px;
		height: 15px;
		font-size: 10px;
		text-align: center;
		line-height: 17px;
		border-radius: 8px 0 ;
		color: white;
		margin-bottom: 5px;
	}
	.top-title-orange{
		background: linear-gradient(to right, #ffbe72, #ff6c41);
	}
	.top-title-green {
		background: linear-gradient(to right, #63cb96, #30d0ac);
	}
	.top-title-red {
		background: linear-gradient(to right, #fc401b, #e02321);
	}
	.top-title-blue{
		background: linear-gradient(to right, #7FCAFF, #3091FF);
		width: 50px;
	}
	.top-title-violet{
		background: linear-gradient(to right,#990066,#ff66ff);
		width: 50px;
	}
	.leaveDetail{
		margin-left: 15px;
		font-size: 14px;
		height: 24px;
		line-height: 24px;
	}
	.spanOne{
		color: #fc401b;
		font-weight: bold;
	}
	.spanTwo{
		font-weight: bold;
	}
	.spanThree{
		color: #999;
	}
	.leaveDetailRemarks{
		margin-left: 15px;
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 10px;
	}
	.rule{
		width: 100%;
		border: 0.5px solid #ededed;
		margin-bottom: 3px;
	}
	.cancelBtnDiv{
		width: 95%;
		text-align: right;
		height: 50px;
		button{
			width: 80px;
			height: 30px;
			line-height: 30px;
			font-size: 14px;
			border-radius: 8px;
			color: #fc401b;
			border: 1px solid transparent;
			background-image: linear-gradient(white,white),linear-gradient(to right,#ffbe72,#ff6c41);
			background-origin: border-box;
			background-clip: padding-box,border-box;
			margin-top: 9px;
		}
	}
	.cancelLeaveBtn{
		display: block;
		width: 92%;
		height: 45px;
		margin: 30px auto 50px;
		border-radius: 8px;
		border: none;
		font-size: 14px;
		background : linear-gradient(to right,#ffbe72,#ff6c41);
		color: white;
	}
	//取消订单
	.cancelOrdersImg{
		float: left;
		margin: 20px 5px 0 15px;
		width: 15px;
		height: 15px;
	}
	//取消订单
	.cnacelOrdersText{
		margin-top: 18px;
		font-size: 15px;
		font-weight: bold
	}
	//取消订单
	.confirmCancelOrdersText{
		margin-left: 35px;
		margin-bottom: 15px;
		font-size: 15px;
		font-weight: bold
	}
</style>
